.container {
    margin-top: 32px;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorCode {
    font-size: 2em;
    margin: 0.67em 0;
    font-weight: bold;
}
